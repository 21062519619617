import { Swiper } from "swiper";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

document.addEventListener("DOMContentLoaded", () => {
    /* const swiper_banner = */
    new Swiper("#home-hero-slider-v2-swiper", {
        modules: [Navigation, Pagination, Autoplay],
        loop: true,
        navigation: {
            nextEl: "#banner-next",
            prevEl: "#banner-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        autoplay: {
            delay: 5000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
        },
        breakpoints: {
            1024: {
                spaceBetween: 16,
            },
        },
    });
});
