document.addEventListener('DOMContentLoaded', () => {
    const zh_overlay_videos = document.querySelectorAll('.zh-overlay-video');
    zh_overlay_videos.forEach(zh_video => {
        const zh_overlay_video_ratio = zh_video.closest('.zh-overlay-video-ratio');
        if (!zh_overlay_video_ratio) {
            return;
        }
        zh_video.addEventListener('play', () => {
            zh_overlay_video_ratio.style.opacity = '1';
            zh_video.setAttribute('controls', ''); // bu satır eklenecek (controls için)
        });
        zh_video.addEventListener('pause', () => {
            zh_overlay_video_ratio.style.opacity = '';
            zh_video.removeAttribute('controls', ''); // bu satır eklenecek (controls için)
        });

        function playPause() {
            if (this.paused) {
                this.play();
                document.getElementById("video-button").style.display = "block";
                return;
            }
            this.pause();
            document.getElementById("video-button").style.display = "block";
        }
        zh_video.addEventListener('click', playPause);
        zh_video.addEventListener('touch', playPause);
    });
});