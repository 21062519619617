document.addEventListener("DOMContentLoaded", () => {
    const splide_containers = document.querySelectorAll(
        "[data-splide-container]"
    );
    splide_containers.forEach((sc) => {
        const amount = parseInt(sc.dataset.splideAmount),
            prev = sc.querySelector("[data-splide-prev]"),
            next = sc.querySelector("[data-splide-next]"),
            track = sc.querySelector("[data-splide-track]");
        prev.addEventListener("click", () => {
            track.scrollLeft -= amount;
        });
        next.addEventListener("click", () => {
            track.scrollLeft += amount;
        });
    });
});
