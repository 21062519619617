import { tns } from "tiny-slider";

document.addEventListener("DOMContentLoaded", () => {
    tns({
        container: ".zm-bottom-infos-slider .splide__list",
        loop: true,
        autoplay: true,
        nav: true,
        navPosition: "bottom",
        mouseDrag: true,
        controls: false,
    });
});